import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { withSSRUserValidate } from 'hocs/withSSRUserValidate/withSSRUserValidate';
import { getDefautServerProps } from 'server/getDefaultServerProps';

export const ProfileRoot = (): null => {
  const router = useRouter();
  const { userOrOrgId } = router.query as { userOrOrgId: string };

  useEffect(() => {
    router.replace(`${encodeURIComponent(userOrOrgId)}/apps`);
  }, [userOrOrgId]);

  return null;
};

export const getServerSideProps = withSSRUserValidate(async ({ params, req, res }) => {
  const defautServerProps = await getDefautServerProps({ params, req, res });
  const { userOrOrgId } = params as { userOrOrgId: string };

  return {
    props: {
      ...defautServerProps,
      trackEventProps: {
        eventName: 'Profile Page Viewed',
      },
      userId: userOrOrgId,
    },
  };
});

// eslint-disable-next-line import/no-default-export
export default ProfileRoot;
